<template>
  <gw-dialog v-model="dialog" @open:dialog="onOpen">
    <template #activator="{ on }">
      <product-preview
        :hide-code="hideCode"
        :name="name"
        :product="product"
        v-on="on"
      >
        <template #subtitle>
          <span class="font-wight-bold primary--text">
            {{ $t("product.select.create") }}
          </span>
        </template>
        <template #icon>
          <slot name="icon" />
        </template>
      </product-preview>
    </template>

    <template #title>
      <v-toolbar color="grey lighten-2" flat>
        <v-toolbar-title v-text="$t('product.select.create.for', { name })" />
        <v-spacer />
        <v-btn
          :disabled="tab === 0"
          class="mr-2"
          color="primary"
          small
          @click="onToggleTab(0)"
        >
          {{ $t("select") }}
        </v-btn>
        <v-btn
          :disabled="tab === 1"
          color="primary"
          small
          @click="onToggleTab(1)"
        >
          {{ $t("create") }}
        </v-btn>
      </v-toolbar>
    </template>

    <template #prepend-actions>
      <v-btn :disabled="!isValid" color="primary" text @click="onApply">
        {{ $t("apply") }}
      </v-btn>
    </template>

    <v-card flat tile>
      <v-window v-if="dialog" v-model="tab">
        <v-window-item>
          <v-card-text v-if="tab === 0">
            <product-select
              v-model="selected"
              :hint="sHint"
              :taxtype-id="taxTypeId"
              without-generated
              @change="onSelect"
            />
          </v-card-text>
        </v-window-item>

        <v-window-item>
          <v-form
            v-if="obProduct && tab === 1"
            class="fill-height"
            @submit.prevent
          >
            <gw-form-observer
              :loading="loading"
              hide-cancel-action
              hide-top-actions
              no-nest
              @save="onCreate"
            >
              <product-settings
                v-model="obProduct"
                :account-purchase-required="isPurchase"
                :account-sales-required="!isPurchase"
                tax-readonly
              />
            </gw-form-observer>
          </v-form>
        </v-window-item>
      </v-window>
    </v-card>
  </gw-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Product, ProductData } from "@planetadeleste/vue-mc-shopaholic";
import ProductPreview from "@/modules/products/components/ProductPreview.vue";
import GwDialog from "@/components/common/GwDialog.vue";
import ProductSelect from "@/modules/products/components/ProductSelect.vue";
import ProductSettings from "@/modules/products/components/tabs/Settings.vue";
import { find, get, map, omit, set } from "lodash";
import Utils from "@/services/Utils";
import GwFormObserver from "@/components/form/GwFormObserver.vue";
import { OfferData, TaxTypeData } from "@planetadeleste/vue-mc-gw";
import { TaxTypeModule } from "@/store/taxtype";
import { AppModule } from "@/store/app";
import useProduct from "@/composables/product";

@Component({
  components: {
    GwFormObserver,
    ProductSelect,
    ProductSettings,
    GwDialog,
    ProductPreview,
  },
})
export default class ProductSelectOrCreate extends Vue {
  @Prop(Object) readonly product!: Product;
  @Prop(Number) readonly productId!: number;
  @Prop(Number) readonly taxTypeId!: number;
  @Prop(Number) readonly currencyId!: number;
  @Prop(String) readonly name!: string;
  @Prop(Boolean) readonly hideCode!: boolean;
  @Prop(Boolean) readonly isPurchase!: boolean | undefined;

  dialog = false;
  loading = false;
  tab = 0;
  selected = 0;
  obProduct: Product | null | undefined = null;

  get company() {
    return AppModule.company;
  }

  get taxTypes() {
    return TaxTypeModule.items;
  }

  get isValid() {
    return !!this.obProduct?.id;
  }

  get sHint() {
    if (this.taxTypeId && this.taxTypes.length) {
      const obItem = find(this.taxTypes, { id: this.taxTypeId }) as
        | TaxTypeData
        | undefined;
      if (obItem) {
        return this.$t("message.product.tax.filtered", { name: obItem.name });
      }
    }

    return undefined;
  }

  onSelect(obModel: Product) {
    this.obProduct = obModel;
  }

  async onCreate() {
    if (!this.obProduct) {
      return;
    }

    this.loading = true;
    this.obProduct.sync();
    await this.obProduct.store();
    this.loading = false;
    this.onApply();
  }

  async onApply() {
    if (this.obProduct) {
      const sMessage = this.$t("ask.assign.record");
      const bRes = await this.$confirm(sMessage as string, {
        color: "warning",
      });
      if (bRes) {
        this.$emit("update:product", this.obProduct);
      }
    }

    this.dialog = false;
  }

  onToggleTab(iValue: number) {
    this.tab = iValue;

    if (iValue === 1) {
      // Made a copy of temporary product without ID and slug attributes
      const obProductData: Partial<ProductData> = this.product
        ? omit(this.product.attributes, ["id", "slug"])
        : {};

      // Change name to current position name
      obProductData.name = this.name;
      set(obProductData, "product_gw.product_id", null);

      // Set sales ON
      set(obProductData, "product_gw.is_sell", !this.isPurchase);

      // Set tax type id
      if (this.taxTypeId) {
        set(obProductData, "product_gw.taxtype_id", this.taxTypeId);
      }

      // Reset offer id and product_id attributes
      let arOfferList = get(
        obProductData,
        "offers",
        []
      ) as Partial<OfferData>[];
      arOfferList = map(arOfferList, (obOfferData) => {
        return omit(obOfferData, ["id", "product_id", "name"]);
      });
      set(obProductData, "offers", arOfferList);

      this.obProduct = new Product(obProductData);

      Utils.nextProductCode().then((response) => {
        if (this.obProduct) {
          this.obProduct.set("code", response.data);
        }
      });
    }
  }

  onOpen() {
    this.tab = 0;

    if (this.product && this.product.id) {
      const { obProduct } = useProduct(this.product);

      if (!obProduct.isTemporary) {
        this.selected = this.product.id;
      }
    }
  }

  async mounted() {
    if (!this.taxTypes.length) {
      await TaxTypeModule.load();
    }
  }
}
</script>
